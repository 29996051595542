import { useQuery } from "react-query";
import { apiHandlers, getMessageId } from "../api";
import { getAdaptedCacheLocale } from "../locales";
import { useLoginContext } from "../api/loginContext";
import { useUserInfoQuery } from "./useUserInfoQuery";
import { useHandleRedirectToBadgePage } from "./useHandleRedirectToBadgePage.ts";
import { useOutlookAssessmentShortPolling } from "./useOutlookAssessmentShortPolling.ts";

export function useAssessmentQuery() {
  const { accessToken } = useLoginContext();
  const { data } = useUserInfoQuery();

  const handleRedirectToBadgePage = useHandleRedirectToBadgePage();
  const outlookAssessmentShortPolling = useOutlookAssessmentShortPolling();

  return useQuery(
    ["assessment"],
    async () => {
      sessionStorage.removeItem("assessmentResult");
      if (data?.soc_email === undefined) {
        throw new Error("No email address found");
      }

      const { messageId, userId } = await getMessageId(accessToken ?? "");
      console.log("mail id for assessment is", messageId);
      console.log("userId for assessment is", userId);

      // expects a promise, so we should be able to use async
      let result = await apiHandlers.assessment.check(
        {
          language: getAdaptedCacheLocale(),
          message_id: messageId,
          source_mailbox: userId,
          token: accessToken ?? undefined,
        },
        { headers: { Authorization: `${accessToken}` } },
      );

      handleRedirectToBadgePage(result.data.assessment_result);
      result = await outlookAssessmentShortPolling(result);

      if (result.data.status === "ERROR") {
        throw new Error("Assessment failed");
      }
      return { assessment_result: result.data.assessment_result, status: result.data.status };
    },
    { enabled: !!accessToken },
  );
}
