import { useHistory } from "react-router-dom";
import { AssessmentResult } from "../api";
import { routes } from "../settings/routes.ts";
import { isFeatureEnabled } from "../featureFlags";

export const useHandleRedirectToBadgePage = () => {
  const history = useHistory();

  return (assessmentResult: AssessmentResult) => {
    const earning = assessmentResult?.earnings ?? [];
    if (
      earning.length > 0 &&
      !sessionStorage.getItem("assessmentResult") &&
      isFeatureEnabled("PS-Gamification2-Rewards")
    ) {
      sessionStorage.setItem("assessmentResult", JSON.stringify(assessmentResult));

      history.push(routes.result.badge.index);
    }
  };
};
